<template>
  <v-container fluid ref="content">
    <v-list-item three-line class="mb-3">
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t('reports.titles.traceability-report') }} v2
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-row class="d-flex justify-space-between align-center mx-4">
      <v-col lg="6" md="6" sm="12" xl="6" class="d-flex flex-row justify-space-around">
        <v-autocomplete
          :no-data-text="$t('general.fields.noDataAvailable')"
          small
          flat
          dense
          solo
          class="mr-3"
          background-color="#EAEAEA80"
          hide-details
          clearable
          item-color="primary"
          :items="orderData(lotsIds)"
          v-model="lote"
          small-chips
          color="primary"
          @change="getProducts(lote)"
          :loading="!(lotsIds && lotsIds.length > 0)"
          :disabled="!(lotsIds && lotsIds.length > 0)"
          @click:clear="checkboxState.lots = false"
        >
          <template v-slot:label>
            {{$t('reports.fields.lot')}} <span class="red--text"><strong>*</strong></span>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip small>
              <span>{{ item }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
        <v-autocomplete
          :no-data-text="$t('general.fields.noDataAvailable')"
          small
          flat
          solo
          dense
          background-color="#EAEAEA80"
          ref="productFilters"
          clearable
          item-color="primary"
          :items="products"
          v-model="product"
          small-chips
          hide-details
          :label="$t('records.label.titles.product')"
          color="primary"
          :loading="loadingProducts"
          :disabled="!(products && products.length > 0) || !(lote && lote.length > 0)"
          @click:clear="clearLoteData()"
        >
          <template v-slot:label>
            {{$t('records.label.titles.product')}} <span class="red--text"><strong>*</strong></span>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip small>
              <span>{{ item }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
        <v-btn
          class="ml-1"
          tile
          :elevation="0"
          height="38"
          color="gray"
          style="border-radius: .2em 1.5em 1.5em .2em;"
          :disabled="!(lote && product)"
          :loading="loadingSearch"
          @click="searchTraceabilityV2Data()"
        >
          <v-icon class="mr-1" color="neuter"
          >mdi-magnify</v-icon
          >
          <template v-slot:loader>
            <v-progress-circular
              style="position: absolute;
                                                   margin-left: -3px;
                                                   height: 15px;"
              color="secondary accent-4"
              indeterminate
              rounded
            />
          </template>
        </v-btn>
      </v-col>
      <v-col lg="4" md="4" sm="8" xl="4">
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-badge
            bordered
            color="neuter"
            overlap
            :content="cantFilters"
            left
            :value="cantFilters"
          >
            <v-btn
              small
              tile
              :disabled="!(lote && product)"
              :elevation="0"
              color="gray"
              style="border-radius: 1.5em;"
              @click="openFilters"
              class="mr-1"
            >
              <v-icon small color="neuter" left>mdi-filter-variant</v-icon>
              {{ $t('general.buttons.filter') }}
            </v-btn>
          </v-badge>
          <!--<v-btn
            small
            tile
            :elevation="0"
            color="gray"
            style="border-radius: 0 1.5em 1.5em 0;"
            @click="exportDialog"
            :disabled="!(this.lote && this.product)"
          >
            <v-icon small color="neuter" left
            >mdi-download-multiple</v-icon
            >
            {{ $t('reports.buttons.traceability') }}
          </v-btn>-->
        </v-toolbar>
      </v-col>
      <v-col cols="12"  v-if="filtersChips && filtersChips.length > 0">
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group show-arrows>
                <v-chip
                  :disabled="loadingSearch"
                  small
                  close
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  @click:close="cleanFilter(chip)"
                >
                  <v-icon left color="t-adj-primary darken-3" small>mdi-filter-variant</v-icon>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </v-col>
    </v-row>
    <v-simple-table :fixed-header="false" light v-if="traceabilityV2 && traceabilityV2.length > 0">
      <template v-slot:default>
        <section style="height: 68vh" class="mx-4 d-flex justify-end align-content-end">
          <div class="d-flex justify-end">
            <div class="d-flex justify-start flex-column"
                 style="max-width: fit-content;"
                 v-for="(result, index) in traceabilityV2"
                 :key="index"
            >
              <div id="pointer" class="my-3 mx-2 d-flex justify-center align-center">
                <p class="ma-0 py-0 d-flex justify-center align-content-center align-center white--text title" style="height: 40px">
                  {{result.storageTypeName}}
                </p>
              </div>
              <div class="d-flex flex-row justify-end align-start align-content-center">
                <div  class="d-block pa-1" v-for="(item, i) in result" style="width: 300px !important;" v-if="i !== 'storageTypeName'">
                  <p class="pa-2 mb-4 text-center" style="font-size: 18px; font-weight: 600">{{translate(item[0].process)}}</p>
                  <v-card elevation="0"
                          v-for="(object, key) in item"
                          :key="key"
                          class="d-flex flex-column justify-center"
                          light
                          flat
                          width="400"
                          :height="object.height > 1 ? `${Math.round(120 * object.height)}` : '120'"
                          :style="`border: 2px solid #44977b4d;
                                   border-radius: 0.3em;`"
                  >
                    <v-card-text class="">
                      <div class="d-block">
                        <span class="d-flex flex-row justify-space-between align-center">
                          <p class="ma-0 pa-0" style="color: black; font-size: 11px"><strong>{{ $t('locations.fields.name').toUpperCase()+':' }}</strong></p>
                          <v-btn :loading="handleItemLoading[key]" x-small @click="handleItem(object, key)" style="color: white" elevation="0" color="teal">
                            <template v-slot:loader>
                              <v-progress-circular color="white" :size="12" indeterminate :width="2"/>
                            </template>
                            #{{object.recordIds.length}}
                          </v-btn>
                        </span>
                        {{object.storageDevice}}
                      </div>
                      <div class="d-block">
                        <p class="ma-0 pa-0" style="color: black; font-size: 11px"><strong>{{ $t('product.fields.name').toUpperCase()+': ' }}</strong> {{translate(object.product)}} </p>
                      </div>
                      <div class="d-block">
                        <p class="ma-0 pa-0" style="color: black; font-size: 11px"><strong>{{ $t('reports.fields.lot').toUpperCase()+': ' }}</strong> {{object.lot}} </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </v-simple-table>
    <!-- Details Export-->
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{
            $t('reports.titles.exportConfigPDF')
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  color="primary"
                  :label="$t('reports.titles.orientation')"
                  item-color="primary"
                  :items="pageEnum"
                  item-value="type"
                  item-text="text"
                  v-model="orientation"
                  small-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="d-flex justify-center">
                <v-switch v-model="evidencePresent" color="primary">
                  <template v-slot:prepend>
                    <v-label ref="label"
                      >{{ $t('reports.titles.evidences') }}
                    </v-label>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="documentPresent" color="primary">
                  <template v-slot:prepend>
                    <v-label ref="label"
                      >{{ $t('reports.titles.documents') }}
                    </v-label>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="gray"
              @click="dialogClose"
              :disabled="exportLoading"
            >
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              small
              text
              color="primary"
              :disabled="!orientation"
              :loading="exportLoading"
              @click="exportRecords"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Details Dialog-->
    <v-row justify="center">
      <v-dialog persistent v-model="dialogDetails" max-width="800px" scrollable>
        <v-card light color="white">
          <v-card-title class="d-flex justify-space-between px-14">
            <v-toolbar fixed flat>
              <span
                class="headline"
                style="font-family: 'Avenir', Helvetica, Arial, sans-serif !important; font-weight: bold !important; color:rgba(35,35,35,0.96) !important;"
                >{{ $t('general.titles.details') }}</span
              >
              <span v-if="recordSelected && recordSelected.length > 0">
                {{ recordSelected.person_in_charge.name }}
              </span>
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container>
              <v-row v-if="dialogDetails">
                <div class="d-flex flex-wrap">
                  <v-card elevation="0" width="100%" class="mt-1 mr-4">
                    <v-card-title
                      class="pb-1 pt-1"
                      style="background-color: rgba(3,172,140,0.21)"
                    >
                      <span style="color: #33a387; font-size: 18px">{{
                        $t('records.label.titles.registryData')
                      }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="padding-left: 0 !important; padding-right: 0 !important; padding-top: 0 !important;"
                    >
                      <v-card elevation="0" width="100%" class="mb-3">
                        <v-card-text
                          style="padding-left: 0 !important; padding-right: 0 !important; padding-top: 0 !important;"
                        >
                          <div
                            class="d-flex flex-wrap mb-2 pa-3"
                            style="background-color: rgba(3,172,140,0.04); border-radius: 3px"
                          >
                            <div
                              style="width: 200px; padding:5px;"
                              v-for="(values, i) in defaultHeaders"
                              :key="i"
                              v-if="i < 6"
                            >
                              <p
                                v-if="values.value !== 'action'"
                                class="black--text mb-0"
                                style="font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 14px; font-weight: 400"
                              >
                                {{ values ? values.text : '' }}:
                              </p>
                              <p
                                v-if="values.value !== 'action'"
                                class="black--text mb-2"
                                style="font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 16px; font-weight: 600"
                              >
                                {{ getDetailsMainData(values.value) }}
                              </p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-divider />
                  </v-card>
                  <div
                    style="width: 250px"
                    v-if="existStructures"
                    v-for="(item, index) in getStructures"
                    :key="item.id"
                  >
                    <div
                      style="background-color: rgba(3,172,140,0.02); border-radius: 3px"
                      class="ma-2 pa-2"
                    >
                      <p
                        class="black--text mb-0"
                        style="font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 14px; font-weight: 400"
                      >
                        {{ translate(item.structure.language_key, true) }}:
                      </p>
                      <p
                        class="black--text mb-0"
                        style="font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 16px; font-weight: 600"
                        v-if="
                          typeof item.recordStructureValue.record_value ===
                            'object'
                        "
                        v-for="structureName in item.recordStructureValue
                          .record_value"
                      >
                        {{ getValue(item.structure, structureName) }}
                      </p>
                      <p
                        class="black--text"
                        v-if="
                          !item.recordStructureValue.record_value ||
                            typeof item.recordStructureValue.record_value !==
                              'object'
                        "
                      >
                        <span v-if="item.structure.structure_type === 'PRODUCT'"
                          >{{ (index += 1) }}- </span
                        >{{
                          getValue(
                            item.structure,
                            item.recordStructureValue.record_value
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <v-card
                    elevation="0"
                    width="100%"
                    class="mt-1 mr-4"
                    v-for="(group, index) in getGroups"
                    :key="index"
                    v-if="existGroups"
                  >
                    <v-card-title class="pb-1 pt-1 pl-1">
                      <span style="color: #37A98C; font-size: 14px">{{
                        translate(group.languageKey, true)
                      }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pl-1">
                      <v-card
                        elevation="1"
                        width="100%"
                        class="mb-3 ml-1 mr-1"
                        v-for="row in group.rows"
                        v-bind:key="row.groupRow"
                      >
                        <v-card-text>
                          <div class="d-flex flex-wrap">
                            <div
                              style="width: 200px"
                              class="mr-2"
                              v-for="values in row.values"
                            >
                              <span class="grey--text"
                                >{{
                                  values
                                    ? translate(
                                        values.structure.language_key,
                                        true
                                      )
                                    : ''
                                }}:</span
                              >
                              <p
                                class="black--text ml-1 mb-2"
                                v-for="(item, index) in values
                                  .recordStructureValue.record_value"
                                :key="index"
                              >
                                {{ getValue(values.structure, item) }}
                              </p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-toolbar flat fixed>
              <v-icon
                class="pl-3"
                v-if="
                  recordSelected.evidences &&
                    recordSelected.evidences.length > 0
                "
                color="primary"
                @click="getEvidences"
              >
                mdi-download-multiple
              </v-icon>
              <v-spacer />
              <v-btn small tile outlined color="gray" @click="closeDetails">
                {{ $t('general.buttons.close') }}
              </v-btn>
            </v-toolbar>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Records Dialog-->
    <v-row justify="center">
      <v-dialog v-model="dialogRecords" max-width="1200px" scrollable @click:outside="clearTable()">
        <v-data-table
          id="record-table"
          :loading="loading"
          :headers="defaultHeaders"
          :items="recordsByIds"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :options.sync="options"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
          :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
        >
          <template v-slot:header.action>
            <span class="d-flex justify-center align-center align-content-center">
              {{ $t('general.fields.action') }}
            </span>
          </template>

          <template v-slot:no-data>
            <div class="d-flex justify-center align-center">
              {{ $t('general.ui.not_data_found') }}
            </div>
          </template>

          <template v-slot:no-results>
            <div class="d-flex justify-center align-center">
              {{ $t('general.ui.not_search_found') }}
            </div>
          </template>

          <template v-slot:loading>
            {{ $t('general.notification.loadData') }}
          </template>

          <template v-slot:body="{ items }">
            <v-progress-linear
              style="visibility: hidden"
              v-if="loading"
              indeterminate
              color="primary"
            />
            <td :colspan="defaultHeaders.length" v-if="loading">
              <div class="d-flex justify-center align-center mt-2">
                {{ $t('general.notification.loadData') }}
              </div>
            </td>

            <td
              :colspan="defaultHeaders.length"
              v-if="
            !(recordsByIds && recordsByIds.length > 0) && !loading
          "
            >
              <div class="d-flex justify-center align-center mt-2">
                {{ $t('general.ui.not_data_found') }}
              </div>
            </td>
            <tr v-for="item in items">
              <td v-if="showCheckBox">
                <v-checkbox
                  v-model="selectedItems"
                  :value="item"
                  class="ma-0 pa-0"
                  hide-details
                  color="primary"
                />
              </td>
              <td v-for="header in defaultHeaders">
                <div v-if="header.value === 'sdi_origin_name'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small>{{
                          (item.sdi_origin.primaryLocation.name +
                            ' | ' +
                            translate(item.sdi_origin.control_label))
                            | truncate(20)
                        }}</v-chip>
                    </template>
                    <span style="font-size: 12px; color: #f5f5f5">
                  {{
                        item.sdi_origin.primaryLocation.name +
                        ' | ' +
                        translate(item.sdi_origin.control_label)
                      }}
                </span>
                  </v-tooltip>
                </div>
                <div v-else-if="header.value === 'sdi_destination_name'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small>{{
                          (item.sdi_destination.primaryLocation.name +
                            ' | ' +
                            translate(item.sdi_destination.control_label))
                            | truncate(20)
                        }}</v-chip>
                    </template>
                    <span style="font-size: 12px; color: #f5f5f5">
                  {{
                        item.sdi_destination.primaryLocation.name +
                        ' | ' +
                        translate(item.sdi_destination.control_label)
                      }}
                </span>
                  </v-tooltip>
                </div>
                <div v-else-if="header.value === 'sdt_to_show_translate'">
                  <v-chip small>{{
                      translate(item.process.sdt_to_show.language_key)
                    }}</v-chip>
                </div>
                <div v-else-if="header.value === 'record_date_time'">
                  <span>{{ getDateFormat(item.record_date_time) }}</span>
                </div>
                <div v-else-if="header.value === 'process_name'">
                  <v-chip class="ma-1" small>{{
                      translate(item.process.language_key)
                    }}</v-chip>
                </div>
                <div v-else-if="header.value === 'person_in_charge_name'">
                  <v-chip class="ma-1" small>{{
                      item.person_in_charge.name
                    }}</v-chip>
                </div>
                <div
                  v-else-if="header.value === 'action'"
                  class="d-flex justify-center align-center"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="neuter"
                        small
                        class="mr-2"
                        @click="getDetails(item)"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </template>
                    <span>
                  {{ $t('general.buttons.show') }}
                  <span style="font-size: 12px; color: #AAAAAA">
                    {{ $t('menu.records') }}
                  </span>
                </span>
                  </v-tooltip>
                </div>
                <div v-for="column in item.extraColumns">
                  <div v-if="header.value === translate(column.language_key)">
                    <v-tooltip max-width="350" top>
                      <template v-slot:activator="{ on }">
                        <v-chip v-on="on" small>{{
                            column.recordValues | truncate(30)
                          }}</v-chip>
                      </template>
                      <span style="font-size: 12px; color: #f5f5f5"
                      >{{ column.recordValues }}
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment'
  import _ from 'lodash'
  import 'jspdf-autotable'
  import DocumentsService from '../../services/DocumentsService'
  import LanguajeService from '../../services/LanguajeService'
  import axios from 'axios'
  import { generalMixin } from '../../mixins/general.mixin'
  import sortList from "../../services/OrderListService";
  import ReadMore from "../../components/ReadMoreComponent";

  const FILTER_VALUES = [
    'company_id',
    'min_punctuation',
    'max_punctuation',
    'surveyStateType',
    'template_names',
    'pageableDTO',
    ' start_record_date_time',
    'end_record_date_time',
    'storage_device_ids',
    'document_authority_ids',
    'document_type_ids',
    'document_description_ids',
    'document_date',
    'expiration_date',
    'storage_devices_ids',
    'storage_device_names',
    'storage_device_names_list',
    'structure',
    'lots',
    'record_ids',
    'sellers_names',
    'contracts_names',
    'volumeB_range',
    'volumeN_range',
    'quebra_range',
    'contracts_dates',
    'withExternalVisibility'
  ]

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    components: { ReadMore },
    mixins: [generalMixin],
    data: () => ({
      checkboxState: {
        products: false,
        lots: false,
      },
      searchRecords: false,
      logoPositionX: 0,
      doc: '',
      evidenceListUrl: [],
      documentListUrl: [],
      indexEvidence: 0,
      indexDocument: 0,
      imgDataList: [],
      importDialog: false,
      sortBy: 'record_date_time',
      sortDesc: true,
      profile: null,
      dialogDetails: false,
      dialogRecords: false,
      // FILTERs
      lote: null,
      product: null,
      period: [],
      filtersDate: [],
      filterdsRecords: [],
      filterdsRecordsBackUp: [],
      filterdsRecordsIdChecked: [],
      LotsOuts: [],
      menu: false,

      show: false,
      fieldsFilters: [],
      structureFilter: [],
      refers: {
        record_date_time: '',
      },
      showDialog: false,
      // config
      orientation: null,
      documentPresent: true,
      evidencePresent: true,
      exportLoading: false,
      message: i18n.t('general.notification.loadData'),
      selectedItems: [],
      showCheckBox: false,
      options: {},
      lotsIds: [],
      loadingProducts: false,
      loadingSearch: false,
      loading: false,
      handleItemLoading: {},
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'DESC',
        }
      },
    }),

    watch: {
      options: {
        handler() {
          this.optionsTable = this.options
          const direction =
            this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? 'desc'
              : 'asc'
          const sort =
            this.options.sortBy.length > 0
              ? this.options.sortBy[0]
              : 'record_date_time'
          this.filters.pageableDTO = {
            page: this.options.page - 1,
            size: this.options.itemsPerPage,
            sortBy: sort,
            direction: direction,
          }
        },
        deep: true,
      },
    },

    mounted() {
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')

      this.$root.$on('setFiltersTraceabilityV2', () => {
        this.closeFilterDialog()
      })

      this.$root.$on('getResults', () => {
        this.searchTraceabilityV2Data()
      })
    },

    computed: {
      ...mapGetters({
        documents: 'documents/documentsTraceabilityReport',
        loadingDocuments: 'documents/loading',
        storages: 'documents/storages',
        storagesLoading: 'storage/loading',
        recordsToTraceabilityReport: 'records/recordsToTraceabilityReport',
        recordsIdsByLot: 'records/recordsIdsByLot',
        recordsCompanyById: 'records/recordsCompanyById',
        locationsList: 'locations/locationsList',
        locationsLoading: 'locations/loading',
        requireAndMaxRules: 'general/requireAndMaxRules',
        requireRules: 'general/requireRules',
        pageEnum: 'reports/pageEnum',
        products: 'details/products',
        allProccess: 'proccess/proccess',
        allProccessLoading: 'proccess/loading',
        lots: 'details/lots',
        storagesTypesByCompany: "storage_type/storagesTypesByCompany",
        traceabilityV2: "reports/traceabilityV2",
        //----ui-----------------------------------------
        defaultHeaders: 'reports/defaultHeadersTraceability',
        config: 'general/config',
        filterHeader: 'ui/GET_HEADERS_TRACEABILITYV2',
      }),
      recordsByIds: {
        get() {
          return this.$store.getters['reports/recordsByIds']
        },
        set(val) {
          this.$store.commit('reports/SET_RECORDS_BY_IDS', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
      locations: {
        get() {
          return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      recordSelected: {
        get() {
          return this.$store.getters['records/record']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val)
        },
      },
      groupStructures() {
        return this.getGroupByProcess()
      },
      // Exist structures
      existStructures() {
        return (
          this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length >
            0
        )
      },
      // Exist groups
      existGroups() {
        return (
          this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0
        )
      },
      // Get structures
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures
          ? this.recordSelected.recordStructureValuesOrganized.structures
          : []
      },
      // Get groups
      getGroups() {
        if (this.flagGroups) this.flagGroups = false
        return this.recordSelected.recordStructureValuesOrganized.groups
          ? this.recordSelected.recordStructureValuesOrganized.groups
          : []
      }
    },

    async created() {
      await this.initialize()
    },
    methods: {
      ...mapActions({
        fetchListStructures: 'records/fetchListStructures',
        exportRecordsAction: 'records/exportRecords',
        fetchListStorage: 'documents/fetchListStorage',
        fetchListDocuments: 'documents/fetchListDocuments',
        fetchListProduct: 'product/fetchListProduct',
        appointmentsByLote: 'records/appointmentsByLote',
        findRecordById: 'records/findRecordById',
        fetchListStructureLots: 'details/fetchListStructureLots',
        fetchListStructureProducts: 'details/fetchListStructureProducts',
        fetchTraceabilityV2Data: 'reports/fetchDataForTraceabilityV2Report',
        fetchRecordsByIds: 'reports/fetchRecordsByIds',
      }),
      async getProducts(lot) {
        this.loadingProducts = true
        await this.fetchListStructureProducts([this.profile, lot, this.$toast]). finally(() => {
          this.loadingProducts = false
        })
      },
      findStorageType(current) {
        return _.find(this.storagesTypesByCompany, (storage) => {
          return current === this.translate(storage.storage_device_type_key)
        })
      },
      async handleItem(data, key) {
        this.$set(this.handleItemLoading, key, true)
        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        /*const keys = [
          'quebra_range',
          'sellers_names',
          'contracts_names',
          'contracts_dates',
          'volumeB_range',
          'volumeN_range',
          'surveyStateType',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'storage_devices_ids',
          'document_authority_ids',
          'document_type_ids',
          'document_description_ids',
          'document_date',
          'expiration_date',
          'isActive',
          'isActiveOnMobile',
          'isExpired',
          'lots',
          'process_ids',
          'products_ids',
          'sellers_ids',
          'storage_device_types_ids',
          'structure',
          'withExternalVisibility'
        ]
        let filters = _.omit(this.filters, keys)
        filters.record_ids = data.recordIds
        filters.pageableDTO = {
          page: 0,
          size: 10,
          sortBy: "id",
          direction: "desc"
        }*/

        await this.fetchRecordsByIds([
          { record_ids: data.recordIds },
          this.source,
          this.$toast,
        ])
        .then((res) => {
          if(res.length > 0) {
            this.dialogRecords = true
          } else {
            console.log('empty')
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.$set(this.handleItemLoading, key, false)
        })
      },
      checkState(prefix, total, values){
        if( values != null && values.length === total.length ){
          switch( prefix ){
            case 'products': this.checkboxState.products = true;
              break;
            case 'lots': this.checkboxState.lots = true;
              break;
          }
        }else{
          switch( prefix ){
            case 'products': this.checkboxState.products = false;
              break;
            case 'lots': this.checkboxState.lots = false;
              break;
          }
        }
      },
      selectAll( type, length, value, idField, checkbox ){
        if( checkbox === false ){
          this.$nextTick(function(){
            switch( type ){
              case 'products': this.checkboxState.products = false;
                break;
              case 'lots': this.checkboxState.lots = false;
                break;
            }
          })
          return [];
        }

        let aux = [];
        if( length != null ) {

          if (typeof length[0] === 'object') {
            _.forEach(length, (object) => {
              if (!_.find(value, (o) => {
                return o[idField] === object[idField]
              }))
                value.push(object)
            })
          } else {
            _.forEach(length, (text) => {
              if (!value.includes(text))
                value.push(text)
            })
          }

          aux = value;
        }else{
          aux = value;
        }
        return aux;

      },
      clearData() {
        this.lote = null
        this.product = null
      },
      clearTable() {
        this.$store.commit('reports/SET_RECORDS_BY_IDS_ORIGINAL_STATE')
      },
      clearLoteData() {
        this.checkboxState.products = false
        this.lote = null
        this.product = null
        this.$store.commit('reports/SET_RECORDS_BY_IDS_ORIGINAL_STATE')
      },
      getDetailsMainData(type) {
        switch (type) {
          case 'record_date_time':
            return this.getDateFormat(this.recordsCompanyById[type])
          case 'sdi_origin_name':
            return this.recordsCompanyById['sdi_origin']
          case 'sdi_destination_name':
            return this.recordsCompanyById['sdi_destination']
          default:
            return this.recordsCompanyById[type]
        }
      },
      orderData(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      cancelRequest() {
        if (this.source) this.source.cancel('cancelRequest')

        this.message = i18n.t('general.notification.operationCanceled')
        this.source = null
      },
      async refreshRequest() {
        this.message = i18n.t('general.notification.loadData')
        await this.searchTraceabilityV2Data()
      },
      openFilters() {
        this.type = 'traceabilityV2'
        this.drawer = true
      },
      // FILTERS
      cleanFilter(chip) {
        let tag = chip.value
        if (this.filters[tag]) {
          if (this.filtersObjects[tag]) {
            this.filtersObjects[tag] = []
          }
          this.filters[tag] = []
          if (tag === 'sdi_origin_ids') {
            this.locations.origin_locations_filters = []
          } else if (tag === 'sdi_destination_ids') {
            this.locations.destination_locations_filters = []
          }
          if (
            tag === 'end_record_date_time' ||
            tag === 'start_record_date_time'
          ) {
            this.date.filtersRecordDate = []
            this.date.filtersDate = []
            this.filters['end_record_date_time'] = null
            this.filters['start_record_date_time'] = null
            this.filtersObjects.start_record_date_time = null
          }
        }
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },
      applyFilters() {
        this.filters.record_ids =
          this.recordsIdsByLot && this.recordsIdsByLot.length > 0
            ? this.recordsIdsByLot
            : null
        this.filters.storage_device_types_ids =
          this.filtersObjects.storage_device_types_ids &&
          this.filtersObjects.storage_device_types_ids.length > 0
            ? this.getByProperty(
                this.filtersObjects.storage_device_types_ids,
                'storage_device_type_id'
              )
            : null
        this.filters.person_in_charge_ids =
          this.filtersObjects.person_in_charge_ids &&
          this.filtersObjects.person_in_charge_ids.length > 0
            ? this.getByProperty(this.filtersObjects.person_in_charge_ids, 'id')
            : null
        this.filters.process_ids =
          this.filtersObjects.process_ids &&
          this.filtersObjects.process_ids.length > 0
            ? this.getByProperty(this.filtersObjects.process_ids, 'id')
            : null
        this.filters.seasons_ids =
          this.filtersObjects.seasons_ids &&
          this.filtersObjects.seasons_ids.length > 0
            ? this.getByProperty(this.filtersObjects.seasons_ids, 'season_name')
            : null
        this.filters.sdi_destination_ids =
          this.filtersObjects.sdi_destination_ids &&
          this.filtersObjects.sdi_destination_ids.length > 0
            ? this.getByProperty(this.filtersObjects.sdi_destination_ids, 'id')
            : null
        this.filters.sdi_origin_ids =
          this.filtersObjects.sdi_origin_ids &&
          this.filtersObjects.sdi_origin_ids.length > 0
            ? this.getByProperty(this.filtersObjects.sdi_origin_ids, 'id')
            : null

        this.filters.start_record_date_time =
          this.date.filtersRecordDate.length > 0
            ? [
                moment(this.date.filtersRecordDate[0]).format('YYYY-MM-DD') +
                  'T00:00:01',
                moment(this.date.filtersRecordDate[1]).format('YYYY-MM-DD') +
                  'T23:59:59',
              ]
            : []

        this.searchTraceabilityV2Data()
      },

      getCantFilters() {
        let cant = 0
        _.forEach(this.filters, (value, key) => {
          if (!FILTER_VALUES.includes(key) && value !== null) {
            cant +=
              key === 'end_record_date_time' ||
              key === ' start_record_date_time'
                ? 1
                : value.length
          }
        })

        this.cantFilters = cant
      },

      filteredChips() {
        let chips = []
        _.forEach(this.filters, (value, key) => {
          if (
            !FILTER_VALUES.includes(key) &&
            this.filters[key] !== null &&
            this.filters[key].length > 0
          ) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          }
        })

        this.filtersChips = chips
      },
      closeFilterDialog() {
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
        this.drawer = false
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      async initialize() {
        this.fieldsFilters.headers = _.filter(this.headers, header => {
          if (header.type !== 'actions') {
            return header
          }
        })
        this.profile = JSON.parse(localStorage.getItem('profile'))
        await this.fetchListStorage([this.profile, this.$toast])
        await this.fetchListStructures([this.profile, this.$toast])
        await this.fetchListProduct([this.defaultFilters, this.$toast])
        await this.fetchListDocuments([this.profile, this.$toast])
        await this.fetchListStructureLots([this.profile, this.$toast]).finally(
          () => {
            _.forEach(this.lots, lot => {
              this.lotsIds = _.union(this.lotsIds, lot.structure_values)
              this.lotsIds = _.filter(this.lotsIds, o => {
                return o !== null
              })

              this.lotsIds.sort((a, b) => {
                if (a && b) {
                  if (!isNaN(a.split(' ')[0]) && !isNaN(b.split(' ')[0])) {
                    return parseInt(a.split(' ')[0]) - parseInt(b.split(' ')[0])
                  }
                  return a.localeCompare(b)
                }
              })
            })
          }
        )
      },
      // Details
      async getDetails(item) {
        this.loading = true
        await this.findRecordById([item.id, this.$toast]).finally(() => {
          this.loading = false
          this.dialogDetails = true
        })
      },
      closeDetails() {
        // this.editedItem.structures = null;
        // this.editedItem.structuresGroups = null;
        this.$store.commit('records/SET_ORIGINAL_STATE_RECORD')
        this.dialogDetails = false
      },
      cloneArrayStructure() {
        this.recordSelected.cantStructureGroup = Object.assign(
          {},
          this.getGroupByProcess()
        )
      },
      getGroupByProcess() {
        let structures = []
        _.forEach(this.recordSelected.recordStructureValues, structure => {
          _.forEach(
            this.recordSelected.process.processStructureOrganized.groups,
            group => {
              _.forEach(group.rows, row => {
                _.forEach(row.values, processStructure => {
                  if (
                    processStructure.recordStructureValue.processStructureId
                      .id === structure.processStructureId.id &&
                    !_.find(structures, {
                      id:
                        processStructure.recordStructureValue.processStructureId
                          .id,
                    })
                  ) {
                    processStructure.structure.groupStructures = group
                    processStructure.structure.processStructureId =
                      processStructure.recordStructureValue.processStructureId
                    structures.push(processStructure.structure)
                  }
                })
              })
            }
          )
          // if (!!found) {
          //     structures.push(found);
          // }
        })
        return this.recordSelected.process
          ? _.groupBy(
              _.groupBy(this.recordSelected.recordStructureValues, o => {
                return !!o.groupStructures
              })[true],
              g => {
                return g.groupStructures.id
              }
            )
          : []
      },
      groupByRow(group) {
        return _.groupBy(group, 'groupRow')
      },
      getEvidences() {
        try {
          DocumentsService.getEvidencas(this.recordSelected.id).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, 'evidences.zip')
            }
          )
        } catch (error) {
          this.$toast.info(i18n.t('records.notification.evidencesCero'), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        }
      },
      getRowDetailsGroupCant(groupId) {
        return _.maxBy(this.getStructuresValuesGroupStructure()[groupId], g => {
          return g.groupRow
        }).groupRow
      },
      getStructuresValuesGroupStructure() {
        let group = {}
        group = _.groupBy(
          _.groupBy(
            Object.assign({}, this.recordSelected.recordStructureValues),
            o => {
              return !!o.groupStructures
            }
          )[true],
          g => {
            return g.groupStructures.id
          }
        )
        return Object.assign({}, group)
      },
      dialogClose() {
        this.orientation = null
        this.documentPresent = true
        this.evidencePresent = true
        this.showDialog = false
        this.exportLoading = false
        this.evidenceListUrl = []
        this.documentListUrl = []
        this.indexEvidence = 0
        this.indexDocument = 0
      },
      exportDialog() {
        this.showDialog = true
      },
      checkFileExtension(extenxion) {
        const extension = ['jpg', 'jpeg', 'png']
        return extension.includes(extenxion)
      },

      // EXPORT FUNCTIONS--------------------
      async exportRecords() {
        this.exportLoading = true

        const keys = ['filterValues', 'record_ids', 'language']
        const filters = _.pick(this.filters, keys)

        if (this.selectedItems && this.selectedItems.length > 0) {
          filters.record_ids =
            this.selectedItems && this.selectedItems.length > 0
              ? this.getByProperty(this.selectedItems, 'id')
              : []
        } else {
          filters.record_ids = this.recordsIdsByLot
        }

        let fields = []
        _.forEach(this.filters, (filter, key) => {
          if (
            this.filters[key] ||
            (this.filters[key] &&
              this.filters[key] instanceof Array &&
              this.filters[key].length > 0) ||
            (this.filters[key] instanceof Date && this.filters[key].length > 0)
          ) {
            switch (key) {
              case 'company_id':
                fields.push({
                  key: i18n.t('modules.name.Company'),
                  value: this.profile.company_name_key,
                })
                return
              case 'lots':
                fields.push({
                  key: i18n.t('records.filters.lot'),
                  value: this.filters.lots.join(', '),
                })
                return
              case 'person_in_charge_ids':
                fields.push({
                  key: i18n.t('records.fields.person'),
                  value: this.getByProperty(
                    this.filtersObjects.person_in_charge_ids,
                    'name'
                  ).join(', '),
                })
                return
              case 'process_ids':
                fields.push({
                  key: i18n.t('records.fields.process'),
                  value: this.getByProperty(
                    this.filtersObjects.process_ids,
                    'proccess_name'
                  ).join(', '),
                })
                return
              case 'products_ids':
                fields.push({
                  key: i18n.t('records.label.titles.product'),
                  value: this.getByProperty(
                    this.filtersObjects.products_ids,
                    'product_name'
                  ).join(', '),
                })
                return
              case 'sdi_origin_ids':
                fields.push({
                  key: i18n.t('records.fields.origin'),
                  value: this.getByProperty(
                    this.filtersObjects.sdi_origin_ids,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'sdi_destination_ids':
                fields.push({
                  key: i18n.t('records.fields.destination'),
                  value: this.getByProperty(
                    this.filtersObjects.sdi_destination_ids,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'seasons_ids':
                fields.push({
                  key: i18n.t('records.fields.season'),
                  value: this.getByProperty(
                    this.filtersObjects.seasons_ids,
                    'season_name'
                  ).join(', '),
                })
                return
              case 'end_record_date_time':
                fields.push({
                  key: i18n.t('records.fields.date'),
                  value: this.date.filtersDate,
                })
                return
              case 'storage_device_types_ids':
                fields.push({
                  key: i18n.t('pre-records.fields.stage'),
                  value: this.getByProperty(
                    this.filtersObjects.storage_device_types_ids,
                    'storage_type_name'
                  ).join(', '),
                })
                return
            }
          }
        })

        filters.filterValues = fields
        filters.language = LanguajeService.getLenguajeName()
        filters.withEvidences = this.evidencePresent
        filters.withDocuments = this.documentPresent
        filters.withExternalVisibility = true
        filters.orientation = this.orientation
        filters.pageableDTO = this.filters.pageableDTO
        filters.currentDate = moment(new Date())
          .format()
          .slice(0, 19)

        await this.exportRecordsAction([
          filters,
          null,
          'pdfTraceability',
          this.$toast,
        ]).finally(() => {
          this.exportLoading = false
          this.dialogClose()
        })
        this.selectedItems = []
      },

      getDateFormat(dateString) {
        return moment(new Date(dateString)).format(this.date.dateFormat)
      },
      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (this.filtersDate.length > 1) {
          if (this.filtersDate[0]) {
            ;[year, month, day] = this.filtersDate[0].split('-')
          }
          if (this.filtersDate[1]) {
            ;[year2, month2, day2] = this.filtersDate[1].split('-')
          }
          this.period = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },

      cancelSearch() {
        this.cancelRequest()
        this.filters.lots = []
        this.product = null
        this.lot = null
        this.filtersDate = []
      },
      searchTraceabilityV2Data() {
        if (this.lote !== '' && this.lote !== null) {
          this.loadingSearch = true

          const CancelToken = axios.CancelToken
          this.source = CancelToken.source()

          this.filters.company_id = this.profile.company_id
          this.filters.record_ids = []

          const keys = [
            'sellers_names',
            'contracts_names',
            'volumeB_range',
            'volumeN_range',
            'quebra_range',
            'contracts_dates',
            'surveyStateType',
            'structure',
            'template_names',
            'min_punctuation',
            'max_punctuation',
            'storage_devices_ids',
            'document_authority_ids',
            'document_type_ids',
            'document_description_ids',
            'document_date',
            'expiration_date',
            'isExpired',
            'isActive',
            'sellers_ids',
            'withExternalVisibility',
            'language',
            'products_ids'
          ]

          let filters = _.omit(this.filters, keys)
          filters.lots = [this.lote]
          filters.products = this.product ? [this.product] : []
          filters.pageableDTO = {
            page: 0,
            size: 10,
            sortBy: "id",
            direction: "desc"
          }

          this.fetchTraceabilityV2Data([filters, this.$toast, this.source]).finally(() => {
            this.loadingSearch = false
          })
        }
      },
    replace (event){
      this.lote =  event.currentTarget.value.toString().replaceAll('"','')
    },},
    destroyed() {
      this.$store.commit('general/SET_ORIGINAL_STATE_CONFIG')
      this.$store.commit('reports/SET_TRACEABILITYV2_ORIGINAL_STATE')
      this.$root.$off('setFiltersTraceabilityV2')
      this.$root.$off('getResults')
    },
  }
</script>

<style type="scss">
.v-data-table .v-data-table__wrapper {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

#record-table.v-data-table {
  margin: 0 !important;
}
:deep(.v-chip-group .v-slide-group__content) {
  display: flex !important;
  justify-content: flex-start !important;
}
:deep(.v-toolbar__content) {
  padding: 0 !important;
}
</style>
